<template>
  <!--begin::Dashboard-->
  <div class="row g-5 gx-xxl-8">
    <div class="col-xxl-6">
      <TenantStatistic
        widget-classes="card-xl-stretch-50 mb-xl-8"
        widget-color="danger"
        chart-height="200"
        stroke-color="#cb1e46"
      ></TenantStatistic>
    </div>
    <div class="col-xxl-6">
      <TenantInfo
        class="card-xl-stretch-40 mb-8 cmb-xl-8"
        :tenantName="tenantStatistics.tenantEstateName"
        :barnyardCount="tenantStatistics.barnyardCount"
        :animalCount="tenantStatistics.animalCount"
        :occupancyRate="tenantStatistics.occupancyRate"
        :totalAnimalValuePrice="tenantStatistics.totalAnimalValuePrice"
        :totalAnimalValueCurrency="tenantStatistics.totalAnimalValueCurrency"
      >
      </TenantInfo>
      <MostValuableAnimal
        :valuableAnimalId="tenantStatistics.valuableAnimalId"
        :valuableAnimalEarTag="tenantStatistics.valuableAnimalEarTag"
        :valuableAnimalName="tenantStatistics.valuableAnimalName"
        :valuableAnimalLiveWeight="tenantStatistics.valuableAnimalLiveWeight"
        :valuableAnimalColor="tenantStatistics.valuableAnimalColor"
        :valuableAnimalGender="tenantStatistics.valuableAnimalGender"
        :valuableAnimalValuePrice="tenantStatistics.valuableAnimalValuePrice"
        :valuableAnimalValueCurrency="
          tenantStatistics.valuableAnimalValueCurrency
        "
        class="card-xl mb-xl-8 mt-5"
      ></MostValuableAnimal>
      <!--
      <AnimalWeight
        widget-classes="card-xl-stretch-35 mb-5 mb-xl-8"
        chart-color="primary"
        chart-height="168"
      ></AnimalWeight>-->
      <!--
      <Accounting
        widget-classes="card-xl-stretch-33 mb-5 mb-xl-8"
        chart-color="primary"
        chart-height="150"
      ></Accounting>
      -->
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import TenantStatistic from "@/presentation/components/dashboard/TenantStatistic.vue";
import TenantInfo from "@/presentation/components/dashboard/TenantInfo.vue";
import MostValuableAnimal from "@/presentation/components/dashboard/MostValuableAnimal.vue";
import AnimalWeight from "@/presentation/components/dashboard/AnimalWeight.vue";
import Accounting from "@/presentation/components/dashboard/Accounting.vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";
import { PAGE_TITLE_PATH, SWAL_MESSAGES } from "@/domain/Constant";
import { useStore } from "vuex";
import { TenantStatisticModel } from "@/domain/dashboard/tenantStatistic/model/TenantStatisticModel";
import SwalNotification from "../plugins/SwalNotification";

export default defineComponent({
  name: "dashboard",
  components: {
    TenantStatistic,
    TenantInfo,
    MostValuableAnimal,
    AnimalWeight,
    Accounting,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();

    const tenantStatistics = ref<TenantStatisticModel>({
      barnyardCount: 0,
      animalCount: 0,
      occupancyRate: 0,
      valuableAnimalId: 0,
      valuableAnimalEarTag: "",
      valuableAnimalName: "",
      valuableAnimalLiveWeight: 0,
      valuableAnimalColor: "",
      valuableAnimalGender: "",
      valuableAnimalValuePrice: 0,
      valuableAnimalValueCurrency: "",
      totalAnimalValuePrice: 0,
      totalAnimalValueCurrency: "",
      tenantEstateName: "",
    });

    const dashboardController =
      store.state.ControllersModule.dashboardController;

    const getTenantStatistic = () => {
      dashboardController
        .getTenantStatistic()
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(tenantStatistics.value, response.getValue());
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.DASHBOARD));
      getTenantStatistic();
    });

    return {
      tenantStatistics,
    };
  },
});
</script>
