
import { defineComponent, onMounted, ref } from "vue";
import { COMPONENT_NAME, PAGE_TITLE_PATH } from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: COMPONENT_NAME.MOST_VALUABLE_ANIMAL,
  props: {
    valuableAnimalId: Number,
    valuableAnimalEarTag: String,
    valuableAnimalName: String,
    valuableAnimalLiveWeight: Number,
    valuableAnimalColor: String,
    valuableAnimalGender: String,
    valuableAnimalValuePrice: Number,
    valuableAnimalValueCurrency: String,
  },
  components: { Field, Form },
  setup(props) {
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);
    const router = useRouter();

    const routeToAnimalInfo = () => {
      router.push({ path: `/animal/${props.valuableAnimalId}/info` });
    };

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.DASHBOARD));

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      translate,
      routeToAnimalInfo,
    };
  },
});
