
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";

export default defineComponent({
  name: "tenantInfos",
  props: {
    tenantName: String,
    barnyardCount: Number,
    animalCount: Number,
    occupancyRate: Number,
    totalAnimalValuePrice: Number,
    totalAnimalValueCurrency: String,
  },
  components: { Field, Form },
  setup() {
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      translate,
    };
  },
});
