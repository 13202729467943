<template>
  <div class="card">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{
          $t("menuPages.dashboard.tenantInfo.header")
        }}</span>
        <span class="text-muted fw-bold fs-7">{{
          $t("menuPages.dashboard.tenantInfo.desc")
        }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body p-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div id="kt_account_profile_details" class="collapse show">
          <div class="card-body border-top">
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>{{ $t("menuPages.dashboard.tenantInfo.tenant") }}:</h5>
                  </label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ tenantName}}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{ $t("menuPages.dashboard.tenantInfo.barnyardCount") }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ barnyardCount }} {{ $t("menuPages.dashboard.tenantInfo.unit") }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{ $t("menuPages.dashboard.tenantInfo.animalCount") }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ animalCount }} {{ $t("menuPages.dashboard.tenantInfo.unit") }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{ $t("menuPages.dashboard.tenantInfo.occupancy") }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    %{{ occupancyRate }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t("menuPages.dashboard.tenantInfo.totalAnimalValue")
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{  new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(Number(totalAnimalValuePrice))  }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";

export default defineComponent({
  name: "tenantInfos",
  props: {
    tenantName: String,
    barnyardCount: Number,
    animalCount: Number,
    occupancyRate: Number,
    totalAnimalValuePrice: Number,
    totalAnimalValueCurrency: String,
  },
  components: { Field, Form },
  setup() {
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      translate,
    };
  },
});
</script>
