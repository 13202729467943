<template>
  <div class="card" v-if="valuableAnimalName" @click="routeToAnimalInfo">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{
          $t("menuPages.dashboard.mostValuableAnimal.header")
        }}</span>
        <span class="text-muted fw-bold fs-7">{{
          $t("menuPages.dashboard.mostValuableAnimal.desc")
        }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body p-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div id="kt_account_profile_details" class="collapse show">
          <div class="card-body border-top">
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.name"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ valuableAnimalName }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.eartag"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ valuableAnimalEarTag }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.gender"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ valuableAnimalGender }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.weight"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{
                      new Intl.NumberFormat("tr-TR", {
                        maximumFractionDigits: 0,
                      }).format(Number(valuableAnimalLiveWeight))
                    }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.color"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{ valuableAnimalColor }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>

              <div class="col-md-6">
                <div class="row mb-3">
                  <!--begin::Label-->
                  <label class="col-lg-6 col-form-label fw-bold fs-6">
                    <h5>
                      {{
                        $t(
                          "menuPages.dashboard.mostValuableAnimal.animal.value"
                        )
                      }}:
                    </h5>
                  </label>
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-6 col-form-label fw-bold fs-6">
                    {{
                      new Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      }).format(Number(valuableAnimalValuePrice))
                    }}
                  </div>
                  <!--end::Col-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{
          $t("menuPages.dashboard.mostValuableAnimal.header")
        }}</span>
        <span class="text-muted fw-bold fs-7">{{
          $t("menuPages.dashboard.mostValuableAnimal.desc")
        }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body p-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div id="kt_account_profile_details" class="collapse show">
          <div class="card-body border-top">
            <p class="text-gray-400 fs-5 fw-bold">
              <span>{{ $t("menuPages.animalList.zeroCount") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { COMPONENT_NAME, PAGE_TITLE_PATH } from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { Field, Form } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: COMPONENT_NAME.MOST_VALUABLE_ANIMAL,
  props: {
    valuableAnimalId: Number,
    valuableAnimalEarTag: String,
    valuableAnimalName: String,
    valuableAnimalLiveWeight: Number,
    valuableAnimalColor: String,
    valuableAnimalGender: String,
    valuableAnimalValuePrice: Number,
    valuableAnimalValueCurrency: String,
  },
  components: { Field, Form },
  setup(props) {
    const { t, te } = useI18n();
    const scrollElRef = ref<null | HTMLElement>(null);
    const router = useRouter();

    const routeToAnimalInfo = () => {
      router.push({ path: `/animal/${props.valuableAnimalId}/info` });
    };

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.DASHBOARD));

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      translate,
      routeToAnimalInfo,
    };
  },
});
</script>
