<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-xxl">
    <!--begin::Header-->
    <div :class="`bg-${widgetColor}`" class="card-header border-0 py-5">
      <h3 class="card-title fw-bolder text-white">
        {{ $t("menuPages.dashboard.statistics.header") }}
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Chart-->
      <apexchart
        :class="`bg-${widgetColor}`"
        class="mixed-widget-2-chart card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        height="200"
        type="area"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-p mt-n20 position-relative">
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <inline-svg src="/media/icons/duotune/general/gen032.svg" />
            </span>
            <router-link to="/barnyards" class="text-warning fw-bold fs-6"
              >{{ $t("menuPages.dashboard.statistics.barnyardList") }}
            </router-link>
          </div>
          <div class="col bg-light-primary px-6 py-8 rounded-2 mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <img
                src="/media/svg/1200px-Cowicon.svg.png"
                style="height: 38px; width: 38px"
              />
            </span>
            <router-link to="/animalList" class="text-primary fw-bold fs-6">
              {{ $t("menuPages.dashboard.statistics.animalList") }}
            </router-link>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-light-danger px-6 py-8 rounded-2 me-7 mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
            </span>
            <router-link
              to="/createAnimal"
              class="text-danger fw-bold fs-6 mt-2"
              >{{ $t("menuPages.dashboard.statistics.addAnimal") }}</router-link
            >
          </div>
          <div class="col bg-light-success px-6 py-8 rounded-2 mb-7">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="/media/icons/duotune/medicine/med007.svg" />
            </span>
            <router-link to="/vaccines" class="text-success fw-bold fs-6 mt-2">
              {{ $t("menuPages.dashboard.statistics.vaccines") }}</router-link
            >
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <div class="col bg-light-primary px-6 py-8 rounded-2 me-7">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/icons/duotune/communication/com004.svg" />
            </span>
            <router-link
              to="/animalTags"
              class="text-primary fw-bold fs-6 mt-2"
              >{{ $t("menuPages.dashboard.statistics.animalTag") }}</router-link
            >
          </div>
          <div class="col bg-light-info px-6 py-8 rounded-2">
            <span class="svg-icon svg-icon-3x svg-icon-info d-block my-2">
              <inline-svg src="/media/icons/duotune/communication/com005.svg" />
            </span>
            <router-link to="/breeds" class="text-info fw-bold fs-6 mt-2">
              {{ $t("menuPages.dashboard.statistics.breeds") }}</router-link
            >
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/presentation/assets/ts/components";

import { getCSSVariableValue } from "@/presentation/assets/ts/_utils";

export default defineComponent({
  name: "tenantStatistics",
  props: {
    widgetClasses: String,
    widgetColor: String,
    strokeColor: String,
  },
  setup(props) {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const color = getCSSVariableValue("--bs-" + props.widgetColor);

    const strokeColor = ref(props.strokeColor);

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: strokeColor.value,
          opacity: 0.5,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [strokeColor.value],
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: borderColor,
            width: 1,
            dashArray: 3,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        enabled: false, // added
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
        marker: {
          show: false,
        },
      },
      markers: {
        colors: [color],
        strokeColor: [strokeColor.value],
        strokeWidth: 3,
      },
    };

    const series = [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ];

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      series,
      chartOptions,
    };
  },
});
</script>
